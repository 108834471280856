.thc-profile {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 2rem;

	&__mvc {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	}

	&__projects {
		&-list {
			list-style: none;
			margin: 0;
			padding: 0;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
			grid-gap: 25px;
			animation: fade-in 2s ease-in-out;
		}
	}

	@keyframes fade-in {
		from {
			opacity: 0;
			transform: translateY(10%);
		}

		50% {
			transform: translateY(0%);
		}

		to {
			opacity: 1;
		}
	}
}