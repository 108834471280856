@import url('https://fonts.googleapis.com/css2?family=Mitr&display=swap');

$primary-color: #42adf5;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

h1, h2, h3 {
	font-family: 'Mitr', sans-serif;
}

h1 {
	font-size: 4rem;
}

p {
	line-height: 2rem;
	font-size: 1.2rem;
}

a {
	&:visited {
		color: black;
	}

	&:hover {
		color: $primary-color;
		font-weight: bold;
	}
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}