$primary-color: #42adf5;
$skills: 10;

.thc-home {
	position: absolute;
	max-width: 600px;
	z-index: 0;
	padding: 15px;
	left: 0;
	top: 0;

	&:before {
		content: '';
		position: absolute;
		top:0;
		left:0;
		background: white;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		z-index: -1;
		box-shadow:
				0 0 5px white,
				0 0 15px white,
				0 0 25px white,
				0 0 35px white,
				0 0 45px white,
				0 0 55px white,
				0 0 75px white,
				0 0 95px white,
				0 0 115px white,
				0 0 225px white,
				0 0 335px white,
				0 0 435px white,
				0 0 535px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
	}

	&:after {
		content: '';
		background: url('../assets/tjinhoocheng.png');
		background-position: right bottom;
		background-size: auto 90%;
		background-repeat: no-repeat;
		background-attachment: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -2;
		opacity: 0.7;
		animation: slide-in 1s ease-in;

		@media only screen and (max-width: 600px) {
			background-size: auto 60%;
		}
	}

	@keyframes slide-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 0.7;
		}
	}

	&__skills {
		position: relative;

		&-wrapper {
			height: 4rem;
			overflow: hidden;
		}

		&-list {
			margin: 0;
			color: $primary-color;
			font-size: 2rem;
			font-weight: bold;
			list-style: none;
			animation-duration: 20s;
			animation-name: animate;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
			transition: translate 0s;
			animation-delay: 1s;
			font-family: 'Mitr', sans-serif;

			& li {
				line-height: 4rem;
			}
		}
	}

	@keyframes animate {
		0% {
			transform: translateY(0);
			opacity: 1;
		}

		@for $i from 1 through $skills {
			#{((100 /  $skills) * $i) - 5}% {
				opacity: 0;
			}
			#{(100 /  $skills) * $i}% {
				transform: translateY(-#{$i * 4rem});
				opacity: 1;
			}
		}
	}
}

.thc-highlight {
	font-size: 2rem;
	font-weight: bold;
	color: $primary-color;
	font-family: 'Mitr', sans-serif;

}