.thc-navigation {
	position: sticky;
	top:0;
	padding: 1rem 0;
	background: white;
	z-index: 1;
	border-bottom: 1px solid black;
	font-family: 'Mitr', sans-serif;

	&__list {	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: center;
	text-align: center;

	& a {
		text-decoration: none;
	}}
}