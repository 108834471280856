.thc-app {
	position: relative;

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr minmax(min-content, 1200px) 1fr;
    }

    &__content {
		padding: 2rem;
        grid-column-start: 2;
        position: relative;
    }
}

.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.fade-exit {
    opacity: 1;

}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}