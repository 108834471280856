$pi: 3.14159265358979;
$outer-radius: 54 !default;
$inner-radius: 40 !default;
$animation-speed: 1s !default;

.thc-progress-ring {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &__wrapper {
        width: 140px;
        height: 140px;
        position: relative;
    }

    &__svg {
        transform: rotate(-90deg);
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;

        @for $i from 1 through 100 {
            &.-p#{$i} {
                &:after{
                    content: '0%';

					// Safari not supporting
                    @keyframes increase-#{$i} {
                        @for $j from 1 through 100 {
                            #{$j}% {
                                content: '#{round($i * ($j / 100))}%'
                            }
                        }
                    }

                    animation: increase-#{$i} $animation-speed ease-out forwards;
                }
            }
        }
    }

    &__circle,
    &__fill {
        fill: none;
        stroke-width: 7px;
        r: $outer-radius;
    }

    &__circle {
        stroke: #d9d9d9;
    }

    &__fill {
        stroke: #42adf5;
        stroke-linecap: round;
        stroke-dasharray: $outer-radius * 2 * $pi;
        stroke-dashoffset: $outer-radius * 2 * $pi;

        @for $i from 1 through 100 {
            &.-p#{$i} {
                @keyframes fill-#{$i} {
                    to {
                        stroke-dashoffset: ($outer-radius * (1 - ($i / 100))) * 2 * $pi;
                    }
                }

                animation: fill-#{$i} $animation-speed ease-out forwards;
            }
        }
    }
}
