.thc-card {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	align-items: center;

	&__image {
		& img {
			width: 100%;
		}
		min-height: 145px;
		align-items: center;
		display: flex;
		flex-grow: 1;

		@media only screen and (max-width: 500px) {
			width: 75%
		}
	}

	&__period {
		font-size: 0.9rem;
		font-weight: bold;
		text-align: center;
	}
}